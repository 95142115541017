import { Box, styled } from '@mui/material';

export const ParentLayoutBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingY: theme.spacing(2),
  paddingLeft: theme.spacing(12),
  paddingRight: theme.spacing(12),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  backgroundColor: '#F7FAFF',
  overflowY: 'auto',
  flexDirection: 'column',
  paddingTop: theme.spacing(2),
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));
