/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Card,
  Grid,
  LinearProgress,
  Pagination,
  styled,
  Typography,
} from '@mui/material';

export const StyledCard = styled(Card)({
  backgroundColor: '#FFFFFF',
  borderRadius: 8,
});

export const StyledGrid = styled(Grid)({
  backgroundColor: '#FFFFFF',
  //maxHeight: '100vh',
  flexGrow: 1,
  overflowY: 'auto',
});

export const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxHeight: '3.6em',
});

export const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    marginRight: '8px',
  },
  '& .MuiPaginationItem-ellipsis': {
    marginRight: '8px',
  },
  display: 'flex',
  justifyContent: 'space-evenly',
});

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  margin: theme.spacing(2),
  width: '100%',
}));
