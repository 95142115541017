import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer } from 'recharts';
import FormControl from '@mui/material/FormControl';
import { Box, Select } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {
  ChartContainer,
  ChartHeader,
  ChartSelectors,
  ChartSelector,
  ChartTitle,
  LoaderContainer,
  ChartMainContainer,
  LegendsContainer,
  LegendStyled,
  LegendTitle,
  LegendCount,
} from './chartBox.styled';
import colors from '../../themes/colors';

const ChartBox = ({
  isLoading,
  chartTitle,
  titleExtraContent = <></>,
  enableSelector,
  selectors,
  children,
  pie = false,
  pieLegends = [],
  fcPieCellHover,
  setPieCellHover = () => {},
  style,
}) => {
  return (
    <ChartContainer style={style}>
      <ChartHeader>
        <Box>
          <ChartTitle>{chartTitle}</ChartTitle>
        </Box>

        <ChartSelectors>
          {enableSelector &&
            selectors.map((selector, index) => (
              <ChartSelector key={index}>
                <FormControl fullWidth>
                  <Select value={selector.value} onChange={selector.onChange}>
                    {selector.menuItems}
                  </Select>
                </FormControl>
              </ChartSelector>
            ))}
        </ChartSelectors>
      </ChartHeader>

      {isLoading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <ChartMainContainer>
          <Box
            style={{
              width: pie ? '50%' : '100%',
            }}
          >
            <ResponsiveContainer width="100%">{children}</ResponsiveContainer>
          </Box>
          {pie && (
            <LegendsContainer>
              {pieLegends.map((pl, i) => (
                <Box key={i}>
                  <LegendStyled
                    style={{
                      // color: colors.bluePallet[i % colors.bluePallet.length],
                      borderLeft: `3px solid ${
                        colors.bluePallet[i % colors.bluePallet.length]
                      }`,
                      opacity:
                        fcPieCellHover !== null
                          ? fcPieCellHover === i
                            ? 1
                            : 0.7
                          : 1,
                    }}
                    onMouseEnter={() => setPieCellHover(i)}
                    onMouseLeave={() => setPieCellHover(null)}
                  >
                    <LegendTitle>{pl?.title}</LegendTitle>
                    <LegendCount>{pl?.count}</LegendCount>
                  </LegendStyled>
                  <Box
                    style={{ height: 5 }}
                    onMouseEnter={() => setPieCellHover(i)}
                    onMouseLeave={() => setPieCellHover(null)}
                  />
                </Box>
              ))}
            </LegendsContainer>
          )}
        </ChartMainContainer>
      )}
    </ChartContainer>
  );
};

ChartBox.propTypes = {
  isLoading: PropTypes.bool,
  chartTitle: PropTypes.string,
  titleExtraContent: PropTypes.node,
  enableSelector: PropTypes.bool,
  selectors: PropTypes.array,
  pie: PropTypes.bool,
  pieLegends: PropTypes.array,
  fcPieCellHover: PropTypes.array,
  setPieCellHover: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node,
};

export default ChartBox;
