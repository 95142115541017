/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, Box, Grid } from '@mui/material';

import colors from '../../themes/colors';

export const ChartContainer = styled(Box)({
  backgroundColor: colors.neutralLight.N10,
  padding: '20px 15px',
  borderRadius: 10,
  border: `1px solid ${colors.neutralLight.N50}`,
  marginBottom: 10,
});

export const ChartHeader = styled(Box)({
  paddingBottom: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: 20,
});

export const ChartTitle = styled(Box)({
  fontSize: 18,
  fontWeight: 'bold',
  color: colors.neutralLight.N800,
  margin: '5px 0',
});

export const ChartSelectors = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ChartSelector = styled(Box)({
  width: 150,
  marginLeft: 10,
});

export const LoaderContainer = styled(Box)({
  display: 'flex',
  height: 300,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ChartMainContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  overflow: 'hidden',
  height: 300,
});

export const LegendsContainer = styled(Box)({
  width: '50%',
  overflowY: 'scroll',
  scrollbarWidth: 0,
  padding: '0 10px',
});

export const LegendStyled = styled(Box)({
  padding: 4,
  paddingLeft: 8,
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: colors.neutralLight.N30,
  },
});

export const LegendTitle = styled(Box)({
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
  overflow: 'hidden',
  width: '70%',
  textAlign: 'left',
});

export const LegendCount = styled(Box)({
  width: '30%',
  textAlign: 'right',
});
