import { Box, styled } from '@mui/material';

export const ParentLayoutBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingY: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  backgroundColor: '#F7FAFF',
  overflowY: 'auto',
  flexDirection: 'column',
}));
