/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import { ProtectedRoute } from '../protectedRoute/ProtectedRoute';
import * as PATHS from '../../constants/path';
import NotFoundPage from '../../pages/notFoundPage';
import FileUploaderPage from '../../pages/settings/fileUploaderPage';
import CookiePage from '../../pages/cookiePage';
import PrivacyPolicyPage from '../../pages/privacyPolicyPage';
import TermsOfUsePage from '../../pages/termsOfUsePage';
import LegalPage from '../../pages/legalPage';
import FileCollectionsPage from '../../pages/settings/filesCollectionPage';
import IndividualFileCollectionPage from '../../pages/settings/individualFileCollectionPage';
import SearchPage from '../../pages/searchPage';
import SearchHistoryPage from '../../pages/searchHistoryPage';
import LogoutPage from '../../pages/logoutPage';
import DataSourcesPage from '../../pages/dataSourcesPage';
import AddDataSourcePage from '../../pages/addNewDataSourcePage';
import FinancialReportPage from '../../pages/financialReportPage';
import NotListedCompaniesReportPage from '../../pages/notListedCompaniesReportPage';
import ReportSourcesPage from '../../pages/reportSourcesPage';
import ReportSummaryTopicsPage from '../../pages/reportSummaryTopicsPage';
import isMarketIntelligenceEnabled from '../../constants/marketIntelligence';
import isAnalyticsEnabled from '../../constants/analytics';
import isReadingAssistantEnabled from '../../constants/readingAssistant';
import isAccessControlEnabled from '../../constants/accessControl';
import AnalyticsDashboard from '../../pages/analyticsPages';
import AnalyticsSearchPerformance from '../../pages/analyticsPages/searchPerformance';
import AnalyticsFileCollections from '../../pages/analyticsPages/fileCollections';
import AnalyticsUsersEngagement from '../../pages/analyticsPages/usersEngagement';
import Faq from '../../pages/faqPage';
import AddMetadataPage from '../../pages/settings/filesCollectionPage/addMetaDataPage';
import SearchMetaDataPage from '../../pages/searchMetaDataPage';
import MetaDataNotEnabledErrorPage from '../../pages/searchMetaDataPage/metaDataNotEnabledErrorPage';
import ConversationPage from '../../pages/conversationPage';
import ConversationHistoryPage from '../../pages/conversationHistoryPage';
import isConversationHistoryEnabled from '../../constants/conversationHistory';
import isAppConfigurationEnabled from '../../constants/appConfiguration';
import {
  DASHBOARD,
  FILE_COLLECTIONS,
  SEARCH_PERFORMANCE,
  USERS as ANALYTICS_USERS,
} from '../../constants/analyticsPaths';
import ManageMetadataProperties from '../../pages/settings/individualFileCollectionPage/manageMetadataProperties';
import ReadingAssistant from '../../pages/readingAssistantPage';
import IndividualDataSourcePage from '../../pages/individualDataSourcePage';
import * as FCSettingsEndpoints from '../../constants/filesCollectionSettingsPaths';
import * as AccessControlEndpoints from '../../constants/accessControlPaths';
import {
  GeneralPage,
  DataConfigPage,
  MetaDataConfigPage,
  PreprocessorConfigPage,
  EmbeddingConfigPage,
  RankerConfigPage,
  LLMConfigPage,
  PromptConfigPage,
  SearchResultConfigPage,
  FCIAMPage,
  AddFCIAMPage,
} from '../../pages/settings/filesCollectionSettingsPage';
import {
  General as GeneralAppConfigPage,
  MarketIntelligenceConfig as MIConfigPage,
} from '../../pages/appConfigurationPages';
import {
  GENERAL as GENERAL_CONFIG,
  MARKET_INTELLIGENCE as MI_CONFIG,
} from '../../constants/appConfigurationPaths';
import {
  AddGroupsPage,
  AddPoliciesPage,
  AddRolesPage,
  AddUserRolePage,
  AddUsersGroupPage,
  GroupsPage,
  PoliciesPage,
  RolesPage,
  UserRolePage,
  UsersGroupPage,
  AddUserPage as RBACAddUserPage,
  UsersPage as RBACUsersPage,
} from '../../pages/accessControlPage';
import MiReportTopicsPage from '../../pages/marketIntelligence/miReportTopicsPage';
import MiConfigurationPage from '../../pages/marketIntelligence/miConfigurationPage';
import MiReportSourcesPage from '../../pages/marketIntelligence/miReportSourcesPage';
import MiReportsPage from '../../pages/marketIntelligence/miReportsPage';

const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      {/* <Route path={PATHS.HOMEPAGE} element={<HomePage />} /> */}
      <Route path={PATHS.SERVICES} element={<Navigate to={PATHS.HOMEPAGE} />} />
      <Route
        path={PATHS.HOMEPAGE}
        element={
          <ProtectedRoute path={`${location.pathname}${location.search}`}>
            <SearchPage />
          </ProtectedRoute>
        }
      />
      {isConversationHistoryEnabled && (
        <>
          <Route
            path={`${PATHS.FILE_COLLECTIONS}/:collectionId/${PATHS.CONVERSATION}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <ConversationPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={`${PATHS.FILE_COLLECTIONS}/:collectionId/${PATHS.CONVERSATION}/:conversationId`}
            element={
              <ProtectedRoute path={location.pathname}>
                <ConversationPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={PATHS.CONVERSATION_HISTORY}
            element={
              <ProtectedRoute path={location.pathname}>
                <ConversationHistoryPage />
              </ProtectedRoute>
            }
          />
        </>
      )}

      <Route
        path={PATHS.SEARCH_HISTORY}
        element={
          <ProtectedRoute path={location.pathname}>
            <SearchHistoryPage />
          </ProtectedRoute>
        }
      />

      <>
        <Route
          path={PATHS.FILE_COLLECTIONS}
          element={
            <ProtectedRoute path={location.pathname}>
              <FileCollectionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId`}
          element={
            <ProtectedRoute path={location.pathname}>
              <IndividualFileCollectionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <GeneralPage />
            </ProtectedRoute>
          }
        />
        {isAccessControlEnabled && (
          <>
            <Route
              path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.IAM.endpoint}`}
              element={
                <ProtectedRoute path={location.pathname}>
                  <FCIAMPage />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.IAM.endpoint}${FCSettingsEndpoints.IAM.addEndpoint}`}
              element={
                <ProtectedRoute path={location.pathname}>
                  <AddFCIAMPage />
                </ProtectedRoute>
              }
            />
          </>
        )}
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.DATA_CONFIG.endpoint}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <DataConfigPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.SEARCH_RESULT_CONFIG.endpoint}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <SearchResultConfigPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.METADATA_CONFIG.endpoint}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <MetaDataConfigPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.PREPROCESSOR_CONFIG.endpoint}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <PreprocessorConfigPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.EMBEDDING_CONFIG.endpoint}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <EmbeddingConfigPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.RANKER_CONFIG.endpoint}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <RankerConfigPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.LLM_CONFIG.endpoint}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <LLMConfigPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PATHS.FILE_COLLECTIONS}/:collectionId${PATHS.SETTINGS}${FCSettingsEndpoints.PROMPT_CONFIG.endpoint}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <PromptConfigPage />
            </ProtectedRoute>
          }
        />
      </>

      {isAccessControlEnabled && (
        <>
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.POLICIES.endpoint}${AccessControlEndpoints.ADD_PAGE.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <AddPoliciesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.GROUPS.endpoint}${AccessControlEndpoints.ADD_PAGE.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <AddGroupsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.USER_ROLE.endpoint}${AccessControlEndpoints.ADD_PAGE.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <AddUserRolePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.ROLES.endpoint}${AccessControlEndpoints.ADD_PAGE.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <AddRolesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.USERS_GROUP.endpoint}${AccessControlEndpoints.ADD_PAGE.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <AddUsersGroupPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.USERS.endpoint}${AccessControlEndpoints.ADD_PAGE.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <RBACAddUserPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.POLICIES.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <PoliciesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.GROUPS.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <GroupsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.USER_ROLE.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <UserRolePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.ROLES.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <RolesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.USERS_GROUP.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <UsersGroupPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PATHS.ACCESS_CONTROL}${AccessControlEndpoints.USERS.endpoint}`}
            element={
              <ProtectedRoute path={location.pathname}>
                <RBACUsersPage />
              </ProtectedRoute>
            }
          />
        </>
      )}

      <Route
        path={PATHS.FILE_COLLECTIONS_FILE_UPLOAD}
        element={
          <ProtectedRoute path={location.pathname}>
            <FileUploaderPage />
          </ProtectedRoute>
        }
      />

      <>
        <Route
          path={`${PATHS.DATA_SOURCES}`}
          element={
            <ProtectedRoute path={location.pathname}>
              <DataSourcesPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${PATHS.DATA_SOURCES}/:rowId`}
          element={
            <ProtectedRoute path={location.pathname}>
              <IndividualDataSourcePage />
            </ProtectedRoute>
          }
        />
      </>

      <Route
        path={`${PATHS.ADD_DATA_SOURCE}`}
        element={
          <ProtectedRoute path={location.pathname}>
            <AddDataSourcePage />
          </ProtectedRoute>
        }
      />

      {isMarketIntelligenceEnabled && (
        <>
          <Route
            path={PATHS.MARKET_INTELLIGENCE}
            element={
              <ProtectedRoute path={location.pathname}>
                <FinancialReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.UNLISTED_COMPANY_REPORTS}
            element={
              <ProtectedRoute path={location.pathname}>
                <NotListedCompaniesReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.REPORT_SOURCES}
            element={
              <ProtectedRoute path={location.pathname}>
                <ReportSourcesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.REPORT_SUMMARY_TOPICS}
            element={
              <ProtectedRoute path={location.pathname}>
                <ReportSummaryTopicsPage />
              </ProtectedRoute>
            }
          />
        </>
      )}

      <Route
        path={`${PATHS.FAQ}`}
        element={
          <ProtectedRoute path={location.pathname}>
            <Faq />
          </ProtectedRoute>
        }
      />

      <Route
        path={`${PATHS.FILE_COLLECTIONS}/:collectionId/${PATHS.ADD_METADATA_PAGE}/:fileName`}
        element={
          <ProtectedRoute path={location.pathname}>
            <AddMetadataPage />
          </ProtectedRoute>
        }
      />

      {/* <Route
        path={PATHS.PROFILE}
        element={
          <ProtectedRoute path={location.pathname}>
            <ProfilePage />
          </ProtectedRoute>
        }
      /> */}

      {isAnalyticsEnabled && (
        <>
          <Route
            path={PATHS.ANALYTICS + DASHBOARD.endpoint}
            element={
              <ProtectedRoute path={location.pathname}>
                <AnalyticsDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.ANALYTICS + SEARCH_PERFORMANCE.endpoint}
            element={
              <ProtectedRoute path={location.pathname}>
                <AnalyticsSearchPerformance />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.ANALYTICS + FILE_COLLECTIONS.endpoint}
            element={
              <ProtectedRoute path={location.pathname}>
                <AnalyticsFileCollections />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.ANALYTICS + ANALYTICS_USERS.endpoint}
            element={
              <ProtectedRoute path={location.pathname}>
                <AnalyticsUsersEngagement />
              </ProtectedRoute>
            }
          />
        </>
      )}

      {isReadingAssistantEnabled && (
        <Route
          path={PATHS.READING_ASSISTANT}
          element={
            <ProtectedRoute path={location.pathname}>
              <ReadingAssistant />
            </ProtectedRoute>
          }
        />
      )}

      {isAppConfigurationEnabled && (
        <>
          <Route
            path={PATHS.APP_CONFIGURATION + GENERAL_CONFIG.endpoint}
            element={
              <ProtectedRoute path={location.pathname}>
                <GeneralAppConfigPage />
              </ProtectedRoute>
            }
          />
          {isMarketIntelligenceEnabled && (
            <Route
              path={PATHS.APP_CONFIGURATION + MI_CONFIG.endpoint}
              element={
                <ProtectedRoute path={location.pathname}>
                  <MIConfigPage />
                </ProtectedRoute>
              }
            />
          )}
        </>
      )}

      <Route
        path={`${PATHS.SEARCH_METADATA_PAGE}/:fileCollectionId`}
        element={
          <ProtectedRoute path={location.pathname}>
            <SearchMetaDataPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={`${PATHS.SEARCH_METADATA_PAGE}/:fileCollectionId/${PATHS.METADATA_NOT_ENABLED_ERROR_PAGE}`}
        element={
          <ProtectedRoute path={location.pathname}>
            <MetaDataNotEnabledErrorPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={`${PATHS.FILE_COLLECTIONS}/:rowID/${PATHS.MANAGE_METADATA_PROPERTIES}`}
        element={
          <ProtectedRoute path={location.pathname}>
            <ManageMetadataProperties />
          </ProtectedRoute>
        }
      />

      <Route path={PATHS.NOT_FOUND} element={<NotFoundPage />} />
      <Route path={PATHS.COOKIES} element={<CookiePage />} />
      <Route path={PATHS.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route path={PATHS.TERMS_OF_USE} element={<TermsOfUsePage />} />
      <Route path={PATHS.LEGAL} element={<LegalPage />} />
      <Route path={PATHS.LOGOUT} element={<LogoutPage />} />

      {/* new mi routes */}
      {isMarketIntelligenceEnabled && (
        <>
          <Route
            path={PATHS.MI_REPORT_SOURCES}
            element={
              <ProtectedRoute path={location.pathname}>
                <MiReportSourcesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.MI_REPORT_TOPICS}
            element={
              <ProtectedRoute path={location.pathname}>
                <MiReportTopicsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.MI_REPORTS}
            element={
              <ProtectedRoute path={location.pathname}>
                <MiReportsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.MI_CONFIGURATION}
            element={
              <ProtectedRoute path={location.pathname}>
                <MiConfigurationPage />
              </ProtectedRoute>
            }
          />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
