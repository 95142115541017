/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Alert, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ParentLayoutBox } from './noReportLayout.styled';

const NoReportLayout = ({ status, stockKey }) => {
  const { t } = useTranslation();
  const statusConfig = {
    ERROR: {
      title: t('newMarketIntelligencePage.reports.noReportLayout.error.title'),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.error.subtitle',
      ),
      severity: 'error',
    },
    CREATING: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.creating.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.creating.subtitle',
      ),
      severity: 'success',
    },
    PLANNED: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.planned.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.planned.subtitle',
      ),
      severity: 'info',
    },
    null: {
      title: t('newMarketIntelligencePage.reports.noReportLayout.null.title'),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.null.subtitle',
      ),
      severity: 'warning',
    },
    pending: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.pending.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.pending.subtitle',
      ),
      severity: 'warning',
    },
  };
  return (
    <ParentLayoutBox>
      <Typography sx={{ marginBottom: 2 }} variant="h4">
        {stockKey}
      </Typography>
      {status in statusConfig ? (
        <>
          <Alert severity={statusConfig[status].severity}>
            {statusConfig[status].title}
          </Alert>
          <Typography variant="caption" sx={{ marginTop: 2 }}>
            {statusConfig[status].subtitle}
          </Typography>
        </>
      ) : (
        <>
          <Alert severity={statusConfig['null'].severity}>
            {statusConfig['null'].title}
          </Alert>
          <Typography variant="caption" sx={{ marginTop: 2 }}>
            {statusConfig['null'].subtitle}
          </Typography>
        </>
      )}
    </ParentLayoutBox>
  );
};

NoReportLayout.propTypes = {
  status: PropTypes.string,
  stockKey: PropTypes.string,
};

export default NoReportLayout;
