/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import {
  Box,
  styled,
  CircularProgress,
  FormControl,
  Chip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
export const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

export const CardActionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(1),
}));

export const AddFilesButton = styled(LoadingButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const ConfigureButton = styled(LoadingButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  margin: '20px 0',
  width: '70%',
  fontSize: 16,
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '0 10px',
  },
}));

export const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
  width: '20px !important',
  height: '20px !important',
  position: 'absolute',
  right: '30px',
  bottom: '10px',
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  position: 'relative',
}));

export const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const MenuBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const TagsChip = styled(Chip)(({ theme }) => ({
  '.MuiChip-icon': {
    height: '18px',
  },
  minWidth: '110px',
  width: 'fit-content',
  height: '25px',
}));

export const ImageSelectorContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  width: '100%',
  gap: 10,
  marginBottom: '20px',
});

export const ImageStyled = styled('img')({
  maxWidth: '150px',
  maxHeight: '80px',
  width: 'auto',
  height: 'auto',
  objectFit: 'contain',
});
