import {
  DialogActions,
  DialogContent,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PdfPreview from '../pdfPreview/pdfPreview';
import { Cancel } from '@mui/icons-material';
import {
  DialogHeader,
  StyledCancelButton,
  StyledDialog,
  StyledDialogTitle,
} from './pdfDialogBox.styled';
import PropTypes from 'prop-types';
import React from 'react';

const LARGE_VIEW = 1.4;
const SMALL_VIEW = 0.6;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PdfDialogBox = ({ handleClose, file, open, pageNumber, dataUri }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <StyledDialog
      fullWidth
      onClose={handleClose}
      open={open}
      maxWidth={false}
      TransitionComponent={Transition}
    >
      <DialogHeader>
        <StyledDialogTitle color="primary">{file}</StyledDialogTitle>

        <StyledCancelButton onClick={handleClose} color="error">
          <Cancel />
        </StyledCancelButton>
      </DialogHeader>

      <PdfPreview
        pageNumber={pageNumber}
        dataUri={dataUri}
        scale={matches ? LARGE_VIEW : SMALL_VIEW}
        showWholePdf={true}
      />
    </StyledDialog>
  );
};

PdfDialogBox.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  url: PropTypes.string,
  file: PropTypes.element,
  pageNumber: PropTypes.number,
  dataUri: PropTypes.string,
};
export default PdfDialogBox;
