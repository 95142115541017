import * as React from 'react';
const AskBotIcon = (props) => (
  <svg
    width={32}
    height={33}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.697 8.98244H18.846V12.5314H7.569V14.8414H18.573V18.4114H7.569V24.6064H2.697V8.98244ZM20.5929 24.6064V8.98244H25.6749C28.2999 8.98244 29.4549 10.0534 29.4549 12.8044V20.7214C29.4549 23.4304 28.3209 24.6064 25.6959 24.6064H20.5929ZM23.9319 11.5654V21.8974H24.8559C25.7799 21.8974 26.0949 21.4354 26.0949 20.3644V13.0774C26.0949 12.0064 25.7799 11.5654 24.8769 11.5654H23.9319Z"
      fill="#FE5733"
    />
  </svg>
);
export default AskBotIcon;
