/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { ParentLayoutBox } from './viewReportLayout.styled';
import DisplayTime from '../../../../../components/timezone.component';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const translationJSONPrefix = 'newMarketIntelligencePage';

const ViewReportLayout = ({
  isGetFinancialReportFetching,
  isGetFinancialReportSuccess,
  financialReport,
  handleReportChange,
  allFinancialReports,
  reportData,
  showArchives,
  handleShowArchives,
}) => {
  const { t } = useTranslation();

  const [archiveData, setArchiveData] = useState({});
  useEffect(() => {
    if (reportData) {
      const archive = allFinancialReports?.find(
        (report) => report?.lastUpdatedDate === reportData.lastUpdatedDate,
      );
      setArchiveData(archive);
    }
  }, [reportData]);

  return (
    <ParentLayoutBox>
      {isGetFinancialReportFetching ? (
        <LinearProgress />
      ) : (
        <>
          {isGetFinancialReportSuccess && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexDirection: 'column',
              }}
            >
              {allFinancialReports?.length > 2 && (
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'fit-content',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        sx={{ m: 1 }}
                        value={showArchives}
                        checked={showArchives}
                        onChange={handleShowArchives}
                      />
                    }
                    label={t(
                      `${translationJSONPrefix}.reports.switch.archives`,
                    )}
                  />
                  {showArchives && (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel>Archives</InputLabel>
                      <Select
                        onChange={handleReportChange}
                        size="small"
                        label="Archives"
                        displayEmpty
                        value={archiveData || {}}
                      >
                        {allFinancialReports &&
                          allFinancialReports
                            ?.filter((report) => report.status !== 'ERROR')
                            .map((report, index) => (
                              <MenuItem
                                key={index}
                                value={report}
                                disabled={
                                  reportData?.lastUpdatedDate ===
                                  report?.lastUpdatedDate
                                }
                              >
                                <Stack>
                                  <Typography>{report?.key}</Typography>
                                  <DisplayTime
                                    time={report?.lastUpdatedDate}
                                    format="ddd, D MMM YYYY HH:mm"
                                  />
                                </Stack>
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: 'column',
                }}
              >
                <ReactMarkdown>{financialReport?.summary}</ReactMarkdown>
              </Box>
            </Box>
          )}
        </>
      )}
    </ParentLayoutBox>
  );
};

ViewReportLayout.propTypes = {
  isGetFinancialReportFetching: PropTypes.bool,
  isGetFinancialReportSuccess: PropTypes.bool,
  financialReport: PropTypes.object,
  handleReportChange: PropTypes.func,
  allFinancialReports: PropTypes.array,
  showArchives: PropTypes.bool,
  handleShowArchives: PropTypes.func,
  reportData: PropTypes.object,
};

export default ViewReportLayout;
