/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Typography, Grid, Link, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ContentBox,
  StyledPdfBox,
  StyledPdfContiner,
  AnsWrapper,
  PdfTypography,
  HtmlTypography,
  HtmlPreviewWrapper,
  NoPreviewIcon,
} from './answer.styled';
import PdfPreview from '../../pages/searchPage/pdfPreview';
import DialogBox from '../../pages/searchPage/pdfDialogBox';
import MarkdownComponent from '../markdown.component';
import AnswerFooter from './answer.footer';
import { StyledCircularIcon } from '../../pages/searchPage/pdfPreview/pdfPreview.styled';
import { getPDFDataURI } from '../../utils/functions';
import { extensionRegEx } from '../../utils/regex';
import { PREVIEW_EXTENSIONS } from '../../constants/queryAnswer';

const AnswerWithPdf = ({
  title,
  description,
  metas,
  pdfPreview,
  feedback,
  feedbackAPI,
  result,
  query,
  isDefaultFeedbackRequired,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState(null);
  const [metasWithDataUri, setMetasWithDataUri] = useState([]);

  const fetchAndAppendDataURIs = async (metas) => {
    const dataURIs = {}; // key - url, value - dataURI

    metas.forEach((m) => {
      if (m?.dataURI) return;
      dataURIs[m.url] = '';
    });

    await Promise.all(
      Object.keys(dataURIs).map(async (url) => {
        try {
          const res = await getPDFDataURI(url);
          dataURIs[url] = res;
        } catch (e) {
          dataURIs[url] = 'ERROR';
        }

        const updatedMetas = metas.map((m) => ({
          ...m,
          dataURI: dataURIs[m.url] || m.dataURI,
        }));

        setMetasWithDataUri(updatedMetas);
      }),
    );
  };

  useEffect(() => {
    if (metas && metas.length > 0) {
      const tempMetas = [];

      // filter only pdfs
      metas.forEach((m) => {
        if (PREVIEW_EXTENSIONS.includes(extensionRegEx.exec(m?.fileName)[1]))
          tempMetas.push(m);
      });

      setMetasWithDataUri(tempMetas);
      fetchAndAppendDataURIs(tempMetas);
    }
  }, [metas]);

  const handleDialogClickOpen = (meta) => {
    setMeta(meta);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AnsWrapper>
        <ContentBox>
          <MarkdownComponent markdown={title} />
        </ContentBox>
        <ContentBox>
          <MarkdownComponent markdown={description} />
          {pdfPreview && metas?.length > 0 && metasWithDataUri?.length > 0 && (
            <>
              <Grid container>
                {metasWithDataUri.map((metaWithDataUri, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    justifyContent={'center'}
                    display={'flex'}
                  >
                    <StyledPdfContiner>
                      <StyledPdfBox
                        onClick={() => handleDialogClickOpen(metaWithDataUri)}
                      >
                        {metaWithDataUri?.dataURI ? (
                          <PdfPreview
                            pageNumber={metaWithDataUri.page}
                            scale={0.3}
                            dataUri={metaWithDataUri?.dataURI}
                            isLoading={!metaWithDataUri?.dataURI}
                          />
                        ) : (
                          <StyledCircularIcon />
                        )}
                      </StyledPdfBox>

                      <Link underline="none" color="inherit">
                        <Tooltip title={metaWithDataUri.fileName}>
                          <PdfTypography>
                            {metaWithDataUri.fileName}
                          </PdfTypography>
                        </Tooltip>
                      </Link>
                    </StyledPdfContiner>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {isDefaultFeedbackRequired && (
            <AnswerFooter
              title={title}
              description={description}
              meta={meta}
              feedback={feedback}
              feedbackAPI={feedbackAPI}
              query={query}
              result={result}
            />
          )}
        </ContentBox>
      </AnsWrapper>
      {meta && meta.dataURI !== 'ERROR' && (
        <DialogBox
          open={open}
          dataUri={meta.dataURI}
          file={meta.fileName}
          handleClose={handleDialogClose}
          pageNumber={meta.page}
        />
      )}
    </>
  );
};

AnswerWithPdf.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  metas: PropTypes.array,
  fileName: PropTypes.string,
  pdfPreview: PropTypes.bool,
  feedback: PropTypes.func,
  feedbackAPI: PropTypes.object,
  result: PropTypes.object,
  query: PropTypes.string,
  isDefaultFeedbackRequired: PropTypes.bool,
};

export default AnswerWithPdf;
