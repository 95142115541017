import { Box, Button, Dialog, DialogTitle, styled } from '@mui/material';

export const StyledCancelButton = styled(Button)({});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('sm')]: {
    width: '85%',
  },
}));

export const PdfBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'auto',
});

export const DialogHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
